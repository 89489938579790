import { Component, Input, EventEmitter, Output, ViewChild, ElementRef, OnInit, OnChanges, OnDestroy, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { NbPopoverDirective, NbIconLibraries, NbDialogService, NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Scenario,
  ScenarioFlag,
  ScenarioWithTaskStatus,
} from '@/app/@core/interfaces/business/scenario';
import { environment } from '@/environments/environment';
import { Plan, PlanFlag } from '@/app/@core/interfaces/business/plan';
import { TOGGLE_COLLAPSE_EVENT_LIST } from '@/store/event/event.actions';
import { VALIDATE_DRP } from '@/store/pages/sit/sit.actions';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { select_disabledDateRangeFn, select_presettedDateRanges, select_selectDateRangeDownload, select_selectedPlan } from '@/store/pages/layout/layout.selectors';
import { select_runningEtlTasks } from '@/store/etl/etl.selectors';
import {
  select_sitEditedScenarios,
  select_sit_validating,
  select_sitValidatingScenario,
  select_sitSimulatingScenario
} from '@/store/pages/sit/sit.selectors';
import { UNLOAD_DEMAND_ACTUAL_X_DATA } from '@/store/pages/demand-planning/demand-planning.actions';
import { 
  select_demandsActualXLoadingCount,
  select_demands_api_loading,
  select_impacts_api_loading
} from '@/store/pages/demand-planning/demand-planning.selectors';
import { MAX_SCENARIO_DISPLAY_COUNT } from '@/app/pages/explorer/planning-explorer/planning-explorer.utils';
import { AbilityService } from '@/app/services/ability.service';
import { CrdtService, LocalUser, RemoteUser } from '@/app/@core/services/crdt.service';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { ScenarioDownloadDialogComponent } from './scenario-download-dialog.component';

const HIGHLIGHTED_COLOR_TRANSPARENT_IN_HEX = '1A';
const DOWNLOAD_WITHOUT_DATE_RANGE = [
  'Facility',
  'Product',
  'Product Value',
  'DRP Physical Network',
  'Physical Network',
]

@Component({
  selector: 'cel-planning-scenario-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./planning-scenario-item.component.scss'],
  templateUrl: './planning-scenario-item.component.html',
})
export class PlanningScenarioItemComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(NbPopoverDirective) popover?: NbPopoverDirective;
  @ViewChild('scenarioOptionPopover') scenarioOptionPopover?: NbPopoverDirective;

  /** Scenario to display. */
  @Input() plans?: Plan[];
  @Input() plan?: Plan
  @Input() selectedPlan?: Plan
  @Input() scenario?: ScenarioWithTaskStatus;
  @Input() scenarios?: ScenarioWithTaskStatus[] = [];

  /** Set to true if current scenario is the primary scenario. */
  @Input() isPrimary = false;
  @Input() isHighlighted = false;
  /** Disable any edit buttons if readonly is true. */
  @Input() readonly = false;

  /** Fires if the user want the scenario to be a primary scenario. */
  @Output() setPrimary = new EventEmitter<Scenario>();
  /** Fires if the user want the scenario to be the highlighted scenario. */
  @Output() setHighlighted = new EventEmitter<Scenario>();
  /** Fires if user clicks on the scenario edit button. */
  @Output() edit = new EventEmitter<Scenario>();
  @Output() delete = new EventEmitter<Scenario>();
  /** Fires if user clicks on the scenario remove button. */
  @Output() hide = new EventEmitter<Scenario>();
  @Output() clone = new EventEmitter<Scenario>();
  @Output() download = new EventEmitter<{ scenario: Scenario, plan: Plan, downloadType: string, dateRange: SimpleDateRange }>();
  @Output() export = new EventEmitter<{ scenario: Scenario, plan: Plan }>();

  @ViewChild('itemIdentifier') itemIdentifier: ElementRef | undefined;

  showEtlLoader: boolean = false;

  workspace$ = this.store.select(select_selectedWorkspace);
  selectedPlan$ = this.store.select(select_selectedPlan)
  runningEtlTasks$ = this.store.select(select_runningEtlTasks)

  displayedName = "";
  shouldDisableNetworkOption = false;
  displayFinanceKPIs = false;
  enableSITFeature = false;
  allowExportToS3 = false;
  enableSalesReturn = false;
  enableSITProvision = false;

  demandsActualXLoadingCount$ = this.store.select(select_demandsActualXLoadingCount);
  demandChartDataLoading$ = this.store.select(select_demands_api_loading);
  demandChartImpactLoading$ = this.store.select(select_impacts_api_loading);
  sitEditedScenarios$ = this.store.select(select_sitEditedScenarios);
  isSitValidating$ = this.store.select(select_sit_validating);
  sitValidatingScenario$ = this.store.select(select_sitValidatingScenario);
  sitSimulatingScenario$ = this.store.select(select_sitSimulatingScenario);
  selectableDateRangeFn = this.store.select(select_disabledDateRangeFn);
  presettedDateRanges = this.store.select(select_presettedDateRanges);
  selectedDateRange$ = this.store.select(select_selectDateRangeDownload);

  demandsActualXLoading = false;
  demandChartDataLoading = false;
  demandChartImpactLoading = false;

  canManageScenario?: boolean;

  sitEditedScenarios: string[] = [];
  isSitValidating: boolean = false;
  sitValidatingScenario?: string;
  sitSimulatingScenario?: string;
  simulationError?: string;
  previousPlan = '';
  dateRangeSelectionText: string = 'Date';
  selectedDateRange? :SimpleDateRange

  localUser: LocalUser | null = null;
  remoteUsers: RemoteUser[] = [];
  private dialogRef: NbDialogRef<any> | null = null;

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly iconsLibrary: NbIconLibraries,
    private readonly abilityService: AbilityService,
    private readonly crdtService: CrdtService,
    private readonly dialogService: NbDialogService,
  ) {
    this.iconsLibrary.registerSvgPack('simcel-scenario-icons', {
      'non-primary':
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#C8C8C8"/></svg>',
      edit:
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M10.5 4.15172V10.5C10.5 11.3284 9.82837 12 9 12H1.5C0.671625 12 0 11.3284 0 10.5V3C0 2.17162 ' +
        '0.671625 1.5 1.5 1.5H7.84791L6.34863 3H1.5V10.5H9V5.65209L10.5 4.15172ZM9.34863 1.06055L8.81836 1.59082L10.4092 ' +
        '3.18202L10.9395 2.65174L9.34863 1.06055ZM10.4092 0L9.87891 0.530273L11.4697 2.12147L12 1.59082L10.4092 0ZM3.51562 ' +
        '6.89503L5.10645 8.48585L9.87891 3.71264L8.28809 2.12145L3.51562 6.89503ZM3 9H4.5L3 7.5V9Z" fill="#0C80EB"/></svg>',
      remove:
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M5.99893 0C2.68579 0 0 2.68664 0 6.00021C0 9.31336 2.68579 12 5.99893 12C9.31378 12 12 9.31293 12 ' +
        '6.00021C12 2.68664 9.31378 0 5.99893 0ZM8.57872 7.62482L7.6231 8.57915C7.6231 8.57915 6.11135 6.95712 5.9985 ' +
        '6.95712C5.88736 6.95712 4.37518 8.57915 4.37518 8.57915L3.41913 7.62482C3.41913 7.62482 5.04287 6.13495 5.04287 ' +
        '6.00236C5.04287 5.86762 3.41913 4.37733 3.41913 4.37733L4.37518 3.42085C4.37518 3.42085 5.8998 5.04373 5.9985 ' +
        '5.04373C6.09805 5.04373 7.6231 3.42085 7.6231 3.42085L8.57872 4.37733C8.57872 4.37733 6.95455 5.88908 6.95455 ' +
        '6.00236C6.95455 6.11007 8.57872 7.62482 8.57872 7.62482Z" fill="#D24A43"/></svg>',
      primary:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white"/>' +
        '<path d="M13.4605 5.19154L7.00964 11.6484L3.64844 8.2812L4.19154 7.73809L7.00964 10.5502L12.9174 4.64844L13.4605 ' +
        '5.19154Z" fill="#C8C8C8"/><rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#C8C8C8"/></svg>',
    });
  }

  ngOnInit() {
    const ability = this.abilityService.getAbility();

    this.canManageScenario = ability.can('manage', 'Scenario');

    combineLatest([
      this.workspace$,
      this.selectedPlan$,
      this.runningEtlTasks$,
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([workspace, plan, runningEtlTasks]) => {
      if (workspace?.settings?.isShowNetworkOption === false) {
        this.shouldDisableNetworkOption = true;
      }

      if (workspace.settings?.displayFinanceKpis) {
        this.displayFinanceKPIs = workspace.settings?.displayFinanceKpis
      }

      if (workspace.settings?.enableSITFeature) {
        this.enableSITFeature = workspace.settings?.enableSITFeature
      }

      if (workspace.settings?.enableSalesReturn) {
        this.enableSalesReturn = workspace.settings?.enableSalesReturn
      }

      if (workspace.settings?.enableSITProvision) {
        this.enableSITProvision = workspace.settings?.enableSITProvision
      }

      if (workspace.settings?.allowExportToS3) {
        this.allowExportToS3 = workspace.settings?.allowExportToS3
      }

      if (workspace.settings?.displayMonthYearOnly) {
        this.dateRangeSelectionText = workspace.settings?.displayMonthYearOnly ? 'Month' : 'Date'
      }

      const isActualPlan = plan?.flags?.includes(PlanFlag.ACTUAL)
      const isActualScenario = this.scenario?.id.includes(ScenarioFlag.ACTUAL)

      if (isActualPlan && isActualScenario) {
        this.showEtlLoader = runningEtlTasks.length > 0;
      } else {
        this.showEtlLoader = false;
      }
    });

    this.demandsActualXLoadingCount$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(demandsActualXLoadingCount => {
      if (this.scenario && this.scenario?.id?.includes('actual-')) {
        this.demandsActualXLoading = demandsActualXLoadingCount > 0;
      }
    })

    this.demandChartDataLoading$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(demandChartDataLoading => {
      this.demandChartDataLoading = demandChartDataLoading;
    })

    this.demandChartImpactLoading$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(demandChartImpactLoading => {
      this.demandChartImpactLoading = demandChartImpactLoading;
    })

    this.sitEditedScenarios$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.sitEditedScenarios = data
    })

    this.isSitValidating$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.isSitValidating = data;
    })

    this.sitValidatingScenario$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.sitValidatingScenario = data;
    })

    this.sitSimulatingScenario$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.sitSimulatingScenario = data;
    })

    this.crdtService.localUser$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      this.localUser = user;
    })

    this.crdtService.remoteUsers$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(users => {
      this.remoteUsers = users;
    })

    this.selectedDateRange$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(dateRange => {
      this.selectedDateRange = dateRange
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.plans && changes.plans.currentValue) || (changes.selectedPlan && changes.selectedPlan.currentValue)) {
      this.previousPlan = this.plans?.find(plan => plan.id === this.selectedPlan?.previousPlanCycle)?.name || 'Actual';
    }
  }

  shouldDisableInteractions(): boolean {
    const localUserWorkspaceId = this.localUser?.workspaceId || '';
    const localUserTimestamp = this.localUser?.editingSitScenarios?.[0]?.timestamp || Infinity;

    const editingRemoteUsers = this.remoteUsers.filter(user => 
        user.workspaceId === localUserWorkspaceId &&
        (user.editingScenarioId || user.editingSitScenarios?.length > 0 || user.simulatingScenarioIds?.length > 0)
    );

    const allSitScenarios = editingRemoteUsers.flatMap(user => 
      user.editingSitScenarios?.map(scenario => ({
        id: scenario.id,
        timestamp: scenario.timestamp
      })) || []
    );
    const earliestSitTimestamp = Math.min(...allSitScenarios.map(scenario => scenario.timestamp));

    const editingScenarioIdsSet = new Set(
        editingRemoteUsers.flatMap(editingRemoteUser => {
            const scenarioIds: string[] = [];

            if (editingRemoteUser?.editingScenarioId) {
                scenarioIds.push(editingRemoteUser.editingScenarioId);
            }

            if (localUserTimestamp >= earliestSitTimestamp) {
              if (editingRemoteUser?.editingSitScenarios?.length > 0) {
                scenarioIds.push(
                  ...editingRemoteUser.editingSitScenarios
                    .filter(scenario => localUserTimestamp >= scenario.timestamp)
                    .map(scenario => scenario.id)
                );
              }
            }

            if (editingRemoteUser?.simulatingScenarioIds?.length > 0) {
              scenarioIds.push(...(editingRemoteUser.simulatingScenarioIds || []));
            }

            return scenarioIds;
        })
    );
    const uniqueEditingScenarioIds = Array.from(editingScenarioIdsSet);

    return uniqueEditingScenarioIds.includes(this.scenario?.id || '');
  }

  findEditingRemoteUser() {
    const localUserWorkspaceId = this.localUser?.workspaceId || '';
    const localUserTimestamp = this.localUser?.editingSitScenarios?.[0]?.timestamp || Infinity;

    const editingRemoteUsers = this.remoteUsers.filter(user => 
        user.workspaceId === localUserWorkspaceId &&
        (user.editingScenarioId || (user.editingSitScenarios?.length! > 0) || (user.simulatingScenarioIds?.length! > 0))
    );

    const allSitScenarios = editingRemoteUsers.flatMap(user => 
      user.editingSitScenarios?.map(scenario => ({
        id: scenario.id,
        timestamp: scenario.timestamp
      })) || []
    );
    const earliestSitTimestamp = Math.min(...allSitScenarios.map(scenario => scenario.timestamp));

    const remoteUserEditingCurrentScenario = editingRemoteUsers.find(editingRemoteUser => {
      if (editingRemoteUser?.editingScenarioId === this.scenario?.id) {
          return true;
      }

      if (localUserTimestamp >= earliestSitTimestamp) {
          if (editingRemoteUser?.editingSitScenarios?.some(scenario => 
              scenario?.id === this.scenario?.id && localUserTimestamp >= scenario.timestamp)) {
              return true;
          }
      }

      if (editingRemoteUser?.simulatingScenarioIds?.includes(this.scenario?.id || '')) {
          return true;
      }

      return false;
    });

    return remoteUserEditingCurrentScenario?.name ?? 'Some user';
  }

  isScenarioBeingSimulated(): boolean {
    const localUserWorkspaceId = this.localUser?.workspaceId || '';
    return this.remoteUsers.some(user => 
        user.workspaceId === localUserWorkspaceId &&
        user.simulatingScenarioIds?.includes(this.scenario?.id || '')
    );
}

  closePopover(popover: NbPopoverDirective) {
    if (popover) {
      popover.hide();
    }
  }

  get isLoading(): boolean {
    let demandChartImpactLoading = this.demandChartImpactLoading;
    const isTargetedDataset = 
      this.scenario?.flags?.includes(ScenarioFlag.ACTUAL) ||
      this.scenario?.flags?.includes(ScenarioFlag.FORECAST_BASE) ||
      this.scenario?.id === ScenarioFlag.CURRENT;
    if (isTargetedDataset) {
      // loader will not load for Impact API if dataset is Actual || Current || Forecast Base
      demandChartImpactLoading = false;
    }
    return this.demandChartDataLoading || this.demandsActualXLoading || demandChartImpactLoading;
  }

  onSetPrimary() {
    this.setPrimary.emit(this.scenario);
  }

  onHighlighScenario() {
    this.setHighlighted.emit(this.scenario);
  }

  onEdit() {
    this.edit.emit(this.scenario);

    // TODO: Commented for now since I'm not sure if something would break :D
    // Let's avoid injecting states on reusable components -- it's dangerous and harder to test.
    // this.state.editedScenarioObs.next(this.scenario);

    this.popover?.hide();
    this.store.dispatch(TOGGLE_COLLAPSE_EVENT_LIST({ isCollapsed: false }));
  }

  onHide() {
    const key = this.scenario?.id || '';
    if (key.includes("actual-")) {
      this.store.dispatch(UNLOAD_DEMAND_ACTUAL_X_DATA({ key }));
    }

    this.hide.emit(this.scenario);
    this.popover?.hide();
  }

  onDelete() {
    this.delete.emit(this.scenario);
    this.popover?.hide();
  }

  onDownload(downloadType: string) {
    if (!this.selectedPlan) {
      return
    }

    if (DOWNLOAD_WITHOUT_DATE_RANGE.includes(downloadType)) {
      this.download.emit({
        scenario: this.scenario!,
        plan: this.plan!,
        downloadType: downloadType,
        dateRange: this.selectedDateRange!
      });

      this.popover?.hide();
      return
    }

    this.dialogRef = this.dialogService.open(ScenarioDownloadDialogComponent, {
      closeOnBackdropClick: true,
      context: {
        dateRange: this.selectedDateRange,
        selectableDateRangeFn: this.selectableDateRangeFn,
        dateRangeSelectionText : this.dateRangeSelectionText,
        onSubmit: (ref: NbDialogRef<ScenarioDownloadDialogComponent>) => {
          ref.onClose.pipe(
            takeUntil(this.destroy$)
          ).subscribe((range) => {
            this.download.emit({
              scenario: this.scenario!,
              plan: this.plan!,
              downloadType: downloadType,
              dateRange: range
            });

            this.popover?.hide();
          })
        },
        onCancel: () => {
          return
        }
      }
    })
  }

  onClone() {
    this.clone.emit(this.scenario);
    this.popover?.hide();
  }

  onOpenUI() {
    // Open SIMCEL-UI
    window.open(environment.simulateUiUrl, '_blank');
  }

  onValidateSITChanges(scenarioId) {
    if (this.scenarioOptionPopover) {
      this.scenarioOptionPopover.hide()
    }
    
    this.store.dispatch(VALIDATE_DRP({ scenarioId }))
  }

  onExport() {
    this.export.emit({
      scenario: this.scenario!,
      plan: this.plan!,
    });

    this.popover?.hide();
    return
  }


  highlightedColorTransparent(color?: string): string {
    if (!color) return '';
    return color + HIGHLIGHTED_COLOR_TRANSPARENT_IN_HEX;
  }

  generateShorterScenarioName(scenarioName?: string): string {
    if (!scenarioName) return '';
    return scenarioName.match(/.{1,20}/g)?.[0] + '...';
  }

  // For now, only Forecast Base scenario is not deletable
  isScenarioMarkableAsPrimary(scenario?: Scenario): boolean {
    const isNonMarkableScenario = 
      scenario?.flags?.includes(ScenarioFlag.ACTUAL) ||
      scenario?.flags?.includes(ScenarioFlag.COMMITTED) ||
      scenario?.id === ScenarioFlag.CURRENT ||
      scenario?.id === ScenarioFlag.BASE_DEMAND;
      
    const isBudgetAndNotSelected = 
      this.plan?.flags?.includes(PlanFlag.BUDGET) && 
      this.plan.id !== this.selectedPlan?.id;
      
    return !(isNonMarkableScenario || isBudgetAndNotSelected || this.isPrimary);
  }

  isMaxDisplayedCountReached(): boolean {
    return (this.scenarios?.length || 0) >= MAX_SCENARIO_DISPLAY_COUNT;
  }

  // For now, only Forecast Base scenario is not deletable
  isScenarioClonable(scenario?: Scenario): boolean {
    const isNonClonableScenario = 
      scenario?.flags?.includes(ScenarioFlag.ACTUAL) ||
      scenario?.flags?.includes(ScenarioFlag.COMMITTED) ||
      scenario?.id === ScenarioFlag.CURRENT || 
      scenario?.id === ScenarioFlag.BASE_DEMAND;
  
    const isBudgetAndNotSelected = 
      this.plan?.flags?.includes(PlanFlag.BUDGET) && 
      this.plan.id !== this.selectedPlan?.id;
  
    const isForecastBase = 
      scenario?.flags?.includes(ScenarioFlag.FORECAST_BASE);
  
    return !(isNonClonableScenario || isBudgetAndNotSelected || isForecastBase);
  }


  // For now, only Forecast Base scenario is not deletable
  isScenarioDeletable(scenario?: Scenario): boolean {
    const isNonDeletableScenario = 
      scenario?.flags?.includes(ScenarioFlag.ACTUAL) ||
      scenario?.flags?.includes(ScenarioFlag.COMMITTED) ||
      scenario?.id === ScenarioFlag.CURRENT ||
      scenario?.id === ScenarioFlag.BASE_DEMAND;
      
    const isBudgetAndNotSelected = 
      this.plan?.flags?.includes(PlanFlag.BUDGET) && 
      this.plan.id !== this.selectedPlan?.id;
      
    const isForecastBase = 
      scenario?.flags?.includes(ScenarioFlag.FORECAST_BASE);
  
    return !(isNonDeletableScenario || isBudgetAndNotSelected || isForecastBase);
  }

  // For now, only Forecast Base scenario is not editable
  isScenarioEditable(scenario?: Scenario): boolean {
    const isActualX = scenario?.id?.includes('actual-');
    
    const isActualNotInActualPlan =
      scenario?.flags?.includes(ScenarioFlag.ACTUAL) &&
      !this.selectedPlan?.flags?.includes(PlanFlag.ACTUAL);

    const isCommittedOrBaseDemand = 
      scenario?.flags?.includes(ScenarioFlag.COMMITTED) ||
      scenario?.id === ScenarioFlag.CURRENT ||
      scenario?.id === ScenarioFlag.BASE_DEMAND;
    
    const isBudgetAndNotSelected = 
      this.plan?.flags?.includes(PlanFlag.BUDGET) && 
      this.plan.id !== this.selectedPlan?.id;

    return !(isActualX || isActualNotInActualPlan || isCommittedOrBaseDemand || isBudgetAndNotSelected);
  }

  // For now, only Forecast Base scenario is not hideable
  isScenarioHideable(scenario?: Scenario): boolean {
    if (this.plan?.flags?.includes(PlanFlag.BUDGET) && this.plan.id != this.selectedPlan?.id) {
      return true
    }

    return true;
  }

  canDownloadMasterData(scenario?: Scenario): boolean {
    if (scenario?.flags?.includes(ScenarioFlag.COMMITTED)) {
      return false
    }

    return true
  }

  canDownloadUnconstrainedDRPData(scenario?:Scenario):boolean{
    if (scenario?.flags?.includes(ScenarioFlag.COMMITTED)) {
      return false
    }

    return this.enableSITFeature
  }

  canDownloadScenarioOutput(scenario?:Scenario):boolean{
    if (scenario?.flags?.includes(ScenarioFlag.COMMITTED)) {
      return false
    }

    return !!scenario?.outputDatabase
  }

  canDownloadSupplyPlan(scenario?:Scenario):boolean{
    if (scenario?.flags?.includes(ScenarioFlag.COMMITTED) || scenario?.flags?.includes(ScenarioFlag.ACTUAL)) {
      return false
    }

    return !!scenario?.supplyPlanId
  }

  isScenarioDownloadable(scenario?: Scenario): boolean {
    const isActualX = scenario?.id?.includes('actual-');

    const isCurrentOrBaseDemand =
      scenario?.id === ScenarioFlag.CURRENT ||
        scenario?.id === ScenarioFlag.BASE_DEMAND;

    return !(isActualX || isCurrentOrBaseDemand);
  }

  isScenarioValidatingDRP(scenarioId): boolean {
    return this.sitValidatingScenario == scenarioId;
  }

  isScenarioTriggeringSimulation(scenarioId): boolean {
    return this.sitSimulatingScenario == scenarioId;
  }

  isScenarioSimulatingDRP(scenarioId): boolean {
    const scenario = this.scenarios?.find(s => s.id == scenarioId)

    const hasRunningTask = scenario?.tasksSummary.status === 'inProgress'

    return hasRunningTask;
  }

  isScenarioSimulationFailed(scenarioId): boolean {
    const scenario = this.scenarios?.find(s => s.id == scenarioId)

    const hasRunningTask = scenario?.tasksSummary.status === 'failed'

    this.simulationError = scenario?.tasksSummary.error

    return hasRunningTask;
  }

  canDownloadCostMaster(): boolean {
    return this.displayFinanceKPIs
  }

  canDownloadSalesReturn(): boolean {
    return this.enableSalesReturn
  }

  canDownloadSITProvision(): boolean {
    return this.enableSITFeature && this.enableSITProvision
  }

  isScenarioExportable(scenario?: Scenario): boolean {
    return this.allowExportToS3 && this.isScenarioDownloadable(scenario)
  }

  isScenarioProcessing(scenarioId): boolean {
    return this.isScenarioValidatingDRP(scenarioId)
      || this.isScenarioTriggeringSimulation(scenarioId)
      || this.isScenarioSimulatingDRP(scenarioId)
  }

  isScenarioAllowValidate(scenarioId): boolean {
    return [ScenarioFlag.CURRENT, ScenarioFlag.BASE_DEMAND].indexOf(scenarioId) < 0 && !scenarioId.includes(ScenarioFlag.ACTUAL)
  }
}
