import { Plan, PlanFlag } from '@/app/@core/interfaces/business/plan';
import { SAVE_SCENARIO } from '@/store/pages/layout/layout.actions';
import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Scenario } from '../../../@core/interfaces/business/scenario';
import { refId } from '@/app/@core/interfaces/common/mongo';

@Component({
  selector: 'cel-planning-scenario-badge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './planning-scenario-badge.component.html',
  styleUrls: ['./planning-scenario-badge.component.scss'],
})
export class PlanningScenarioBadgeComponent implements OnInit {
  @Input() plan?: Plan;
  @Input() selectedPlan?: Plan;
  @Input() scenario?: Scenario;
  @Input() isChangeColor?: string;
  @Input() primary?: boolean;
  hideColorPicker: boolean = false;
  hideTextInput: boolean = false;
  color: string = '#0C80EB';

  constructor(private readonly store: Store) { }
  ngOnInit(): void {
    if (this.scenario) {
      this.color = this.scenario.color || '#0C80EB'
    }
  }

  changeColor() {
    if (this.scenario) {
      this.store.dispatch(SAVE_SCENARIO({ 
        scenario: {
          ...this.scenario,
          color: this.color,
          events: this.scenario.events?.map(e => refId(e) || "") || []
        },
        reSimulate: false
      }));
    }
  }

  IsBudgetScenario(): boolean {
    if (!this.plan || !this.plan.flags) {
      return false
    }

    return this.plan.flags.includes(PlanFlag.BUDGET)
  }

}
