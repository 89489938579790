import { Router } from '@angular/router';
import { subMonths, setDate } from 'date-fns';

import { SegmentFilter } from '@/app/@core/interfaces/business/segment';
import { simpleDateRangeChanged } from '@/app/@core/interfaces/common/date-range';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { WorkspaceService } from '@/app/@core/utils';
import {
  CHANGE_DISPLAYED_DATERANGE,
  EDIT_SEGMENT,
  SAVE_SEGMENT,
  SELECTED_DATE_RANGE_CHANGED,
  SELECTED_DATE_RANGE_FORECAST_CHANGED,
  SELECTED_SEGMENT_CHANGED,
  UPDATE_SELECTED_SEGMENT
} from '@/store/pages/layout/layout.actions';
import { select_selectedSegment } from '@/store/pages/layout/layout.baseSelectors';
import {
  select_disabledDateRangeFn,
  select_disabledDateRangeForecastFn,
  select_presettedDateRanges,
  select_presettedDateRangesForecast,
  select_selectedDateRange,
  select_selectedDateRangeForecast
} from '@/store/pages/layout/layout.selectors';
import {
  select_segmentCustomersOptions,
  select_segmentOptions,
  select_segmentProductsOptions,
  select_segments,
  select_segment_settings
} from '@/store/segment/segment.selectors';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { select_latestActual } from '@/store/actual/actual.selectors';
import {
  convertToRangePickerVal,
  convertToSimpleDateRangeFromRangepickerVal
} from '@/utils/calendar';
import { notNullOrUndefined } from '@/utils/notNullOrUndefined';
import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbCalendarMonthCellComponent, NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, filter, map, takeUntil,
  tap, withLatestFrom, startWith, share
} from 'rxjs/operators';
import { PlanningSegmentDialogComponent } from './planning-segment-dialog.component';
import { SegmentManagementComponent } from './segment-management.component';
import { WidgetManagementDialogComponent, WidgetType } from './widget-management.component';
import { FilterType, createBreadcrumb, sortOptionsByFields } from './planning-segment.utils';
import { SELECT_CURRENCY } from '@/store/pages/planning-explorer/planning-explorer.actions';
import { select_selectedCurrency, select_supportedCurrencies } from '@/store/pages/planning-explorer/planning-explorer.selector';

Object.defineProperty(NbCalendarMonthCellComponent.prototype, 'selected', {
  get: function () {
    return this.dateService.isSameMonthSafe(this.date, this.selectedValue.end);
  },
  enumerable: true,
  configurable: true,
});

@Component({
  selector: 'cel-planning-segment',
  styleUrls: ['./planning-segment.component.scss'],
  templateUrl: './planning-segment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanningSegmentComponent implements OnInit, OnDestroy {
  segmentProductsOptions: any[] = [];
  segmentCustomersOptions: any[] = [];
  segmentLocationsOptions: any[] = [];
  segmentDistributorsOptions = this.store.select(select_segmentOptions).pipe(
    map(options => options?.distributors || [])
  );

  useDistributorFilter$ = this.router.events.pipe(
    startWith(null),
    debounceTime(100),
    map(() => this.router.url),
    distinctUntilChanged(),
    map((url) => {
      return url.endsWith('/business-explorer') || url.endsWith('/sit');
    }),
    share(),
  );

  presettedDateRanges;

  segments = this.store.select(select_segments);
  segmentOptions = this.store.select(select_segmentOptions);
  selectedSegment = this.store.select(select_selectedSegment);
  selectedDateRange = this.store.select(select_selectedDateRange);
  selectedDateRangeForecast = this.store.select(select_selectedDateRangeForecast);
  segmentSettings = this.store.select(select_segment_settings);
  selectableDateRangeFn = this.store.select(select_disabledDateRangeFn);
  latestActual = this.store.select(select_latestActual);
  segmentSelectName;
  noDateAllowed = (_: Date) => false;
  displayMonthYearOnly: boolean = false;
  dateRangeSelectionText = "Date";
  isProductEyeOn = false;
  productBreadcrumb: string[] = [];
  isCustomerEyeOn = false;
  customerBreadcrumb: string[] = [];
  isDistributorEyeOn = false;
  distributorBreadcrumb: string[] = [];
  isLocationEyeOn = false;
  locationBreadcrumb: string[] = [];
  supportedCurrencies$ = this.store.select(select_supportedCurrencies);
  supportedCurrencies: string[] = [];
  selectedCurrency$ = this.store.select(select_selectedCurrency);
  selectedCurrency: string = '';

  /** Reference to popover, filled on after view init step. */
  @ViewChild(NbPopoverDirective) popover!: NbPopoverDirective;
  @Output() selectedWidgets = new EventEmitter<WidgetType[]>();
  @Input() widgets: readonly WidgetType[] = [];

  formControl_selectedRange = new FormControl([]);

  readonly component = SegmentManagementComponent;

  /** Unsubscribe helper. */
  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly store: Store,
    private readonly workspaceService: WorkspaceService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('explorer/forecast')) {
      this.presettedDateRanges = this.store.select(select_presettedDateRangesForecast);
      this.selectableDateRangeFn = this.store.select(select_disabledDateRangeForecastFn);
    } else {
      this.presettedDateRanges = this.store.select(select_presettedDateRanges);
      this.selectableDateRangeFn = this.store.select(select_disabledDateRangeFn);
    }

    this.bindStoreToFormControl();

    this.store.select(select_selectedWorkspace).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((workspace: Workspace) => {
      this.displayMonthYearOnly = this.workspaceService.checkIsDisplayMonthYearOnly(workspace);
      if (this.displayMonthYearOnly) {
        this.dateRangeSelectionText = "Month";
      } else {
        this.dateRangeSelectionText = "Date";
      }
    });

    this.store.select(select_segmentCustomersOptions).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data) => {
      this.segmentCustomersOptions = sortOptionsByFields(data, FilterType.Customers);
      this.segmentLocationsOptions = sortOptionsByFields(data, FilterType.Locations);
    })
    this.store.select(select_segmentProductsOptions).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data) => {
      this.segmentProductsOptions = sortOptionsByFields(data, FilterType.Products);
    })
    this.selectedSegment.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((selectedSegment) => {
      const { product, customer, location } = selectedSegment || {};

      this.productBreadcrumb = createBreadcrumb(product, 'Products');
      this.customerBreadcrumb = createBreadcrumb(customer, 'Customers');
      this.locationBreadcrumb = createBreadcrumb(location, 'Locations');
      this.distributorBreadcrumb = createBreadcrumb(selectedSegment.distributor, "Distributor");
    })

    this.selectedCurrency$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((selectedCurrency) => {
      this.selectedCurrency = selectedCurrency;
    })

    this.supportedCurrencies$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((supportedCurrencies) => {
      this.supportedCurrencies = supportedCurrencies;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private bindStoreToFormControl() {
    if (this.router.url.includes('explorer/forecast')) {
      this.selectedDateRangeForecast
        .pipe(
          map(convertToRangePickerVal),
          filter((range) => !!range.length),
          tap((range) => {
            return this.formControl_selectedRange.setValue(range, {
              onlySelf: true,
              emitEvent: false,
              emitModelToViewChange: true,
              emitViewToModelChange: false,
            })
          }
          ),
          takeUntil(this.unsubscribe),
        )
        .subscribe();

      this.formControl_selectedRange.valueChanges
        .pipe(
          map(range => {
            if (this.dateRangeSelectionText === "Month") {
              this.workspaceService.resetRange(range);
            }
            return range;
          }),
          map(convertToSimpleDateRangeFromRangepickerVal),
          filter((data) => !!data.start && !!data.end),
          withLatestFrom(this.selectedDateRangeForecast), // 1. Take currently selected range
          filter(simpleDateRangeChanged), //         2. Check if it changed
          map(([range]) => range), //                3. Discard value included in step 1
          filter(notNullOrUndefined), //             4. Ensure it not null
          tap((data) => this.store.dispatch(SELECTED_DATE_RANGE_FORECAST_CHANGED({ data }))),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else {
      this.selectedDateRange
        .pipe(
          map(convertToRangePickerVal),
          filter((range) => !!range.length),
          tap((range) => {
            return this.formControl_selectedRange.setValue(range, {
              onlySelf: true,
              emitEvent: false,
              emitModelToViewChange: true,
              emitViewToModelChange: false,
            })
          }
          ),
          takeUntil(this.unsubscribe),
        )
        .subscribe();

      this.formControl_selectedRange.valueChanges
        .pipe(
          map(range => {
            if (this.dateRangeSelectionText === "Month") {
              this.workspaceService.resetRange(range);
            }
            return range;
          }),
          map(convertToSimpleDateRangeFromRangepickerVal),
          filter((data) => !!data.start && !!data.end),
          withLatestFrom(this.selectedDateRange), // 1. Take currently selected range
          filter(simpleDateRangeChanged), //         2. Check if it changed
          map(([range]) => range), //                3. Discard value included in step 1
          filter(notNullOrUndefined), //             4. Ensure it not null
          // tap((data) => this.store.dispatch(SELECTED_DATE_RANGE_CHANGED({ data }))),
          tap((data) => this.store.dispatch(CHANGE_DISPLAYED_DATERANGE({ dateRange: data }))),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  openSegmentManagementPopOver() {
    if (!this.popover) return;

    this.popover.rebuild();
  }

  // let segment;
  // this.segments.subscribe(result => {
  //   segment = result.filter(item => item.id == this.choseSegment)[0];
  // })
  // this.store.dispatch(UPDATE_SEGMENT({ segment }))
  /** Fires when the user clicks the "save" segment button. */
  addSavedSegment() {
    this.dialogService.open(PlanningSegmentDialogComponent).onClose.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((savedName) => {
      if (savedName?.name) {
        this.store.dispatch(SAVE_SEGMENT({ segmentName: savedName.name }));
      }
      if (savedName?.choseSegment) {
        let selectedFilter;
        this.selectedSegment.pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(result => selectedFilter = result);
        let segment;
        this.segments.pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(result => {
          segment = result.filter(item => item.id == savedName.choseSegment)[0];
        })
        segment = { ...segment, customer: selectedFilter.customer, product: selectedFilter.product, location: selectedFilter.location }
        this.store.dispatch(EDIT_SEGMENT({ segment }))
        this.store.dispatch(SELECTED_SEGMENT_CHANGED({ segment: segment }));

      }
    });
  }

  openWidgetManagementWindow() {
    this.dialogService
      .open(WidgetManagementDialogComponent, {
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          checkedWidgets: [...this.widgets],
        },
      })
      .onClose.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((widgets) => this.selectedWidgets.emit(widgets));
  }

  onProductFiltersChanged(filters: SegmentFilter[]) {
    this.store.dispatch(UPDATE_SELECTED_SEGMENT({ product: filters }));
  }

  onCustomerFiltersChanged(filters: SegmentFilter[]) {
    this.store.dispatch(UPDATE_SELECTED_SEGMENT({ customer: filters }));
  }

  onDistributorFiltersChanged(filters: SegmentFilter[]) {
    this.store.dispatch(UPDATE_SELECTED_SEGMENT({ distributor: filters }));
  }

  onLocationFiltersChanged(filters: SegmentFilter[]) {
    this.store.dispatch(UPDATE_SELECTED_SEGMENT({ location: filters }));
  }

  onProductEyeClick() {
    this.isProductEyeOn = !this.isProductEyeOn;
  }

  onCustomerEyeClick() {
    this.isCustomerEyeOn = !this.isCustomerEyeOn;
  }

  onLocationEyeClick() {
    this.isLocationEyeOn = !this.isLocationEyeOn;
  }

  onSelectCurrency(event: string) {
    this.store.dispatch(SELECT_CURRENCY({ selectedCurrency: event }));
  }
}
