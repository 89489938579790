import { createAction, props } from '@ngrx/store';

import { Plan } from '@/app/@core/interfaces/business/plan';
import { Segment, SegmentFilter } from '@/app/@core/interfaces/business/segment';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { ExtendedScenario, Scenario } from '@/app/@core/interfaces/business/scenario';
import { ICreatePlanImportParams, IUpdatePlanImportParams } from '@/app/@core/backend/simcel/plan-import.service';
import { IDeletePlanParams } from '@/app/@core/backend/simcel/plan.service';
import { IRenderForecastReportParams } from '@/app/@core/services/forecast-explorer.service';

export const SHOW_SCENARIO = createAction(
  '[🌱 Layout] Show Scenario',
  props<{ scenario: Scenario }>(),
);
export const SET_SCENARIOS = createAction(
  '[🌱 Layout] SETS Scenarios',
  props<{ scenarios: Scenario[] }>(),
);
export const HIDE_SCENARIO = createAction(
  '[🌱 Layout] Hide Scenario',
  props<{ scenario: Scenario }>(),
);
export const HIGHLIGHT_SCENARIO = createAction(
  '[🌱 Layout] Hightlight Scenario',
  props<{ id: string }>(),
);
export const SET_PRIMARY_SCENARIO = createAction(
  '[🌱 Layout] Set Primary Scenario',
  props<{ scenario: ExtendedScenario }>(),
);
export const CLONE_SCENARIO = createAction(
  '[🌱 Layout] Clone Scenario',
  props<{ scenario: ExtendedScenario }>(),
);
export const SAVE_SCENARIO = createAction(
  '[🌱 Layout] Save Scenario',
  props<{ scenario: ExtendedScenario, reSimulate: boolean }>(),
);
export const DELETE_SCENARIO = createAction(
  '[🌱 Layout] Delete Scenario',
  props<{ scenario: Scenario }>(),
);
export const DOWNLOAD_SCENARIO = createAction(
  '[🌱 Layout] Download Scenario',
  props<{ scenario: Scenario, plan: Plan, downloadType: string, dateRange: SimpleDateRange }>(),
);
export const EXPORT_SCENARIO = createAction(
  '[🌱 Layout] Export Scenario',
  props<{ scenario: Scenario, plan: Plan }>(),
);
export const HIDE_DISPLAYED_SCENARIO = createAction(
  '[🌱 Layout] Hide Displayed Scenario',
  props<{ scenario: Scenario }>(),
);
export const ADD_DISPLAYED_SCENARIO = createAction(
  '[🌱 Layout] Add Displayed Scenario',
  props<{ scenario: Scenario }>(),
);

export const CHANGE_DISPLAYED_SCENARIO = createAction(
  '[🌱 Layout] Change Displayed Scenario',
  props<{ scenarioIds: string[] }>(),
);
export const CHANGE_DISPLAYED_DATERANGE = createAction(
  '[🌱 Layout] Change Displayed DateRange',
  props<{ dateRange: SimpleDateRange }>(),
);
export const CHANGE_USING_Y2Y = createAction(
  '[🌱 Layout] Change Using Y2Y',
  props<{ usingY2Y: boolean }>(),
);
export const CHANGE_COMPARE_MODE = createAction(
  '[🌱 Layout] Change Compare Mode',
  props<{ compareMode: string }>(),
);
export const CHANGE_SELECTED_KPIS = createAction(
  '[🌱 Layout] Change Selected Kpis',
  props<{ selectedKpis: any[] }>(),
);
export const DELETE_DISPLAYED_SCENARIO = createAction(
  '[🌱 Layout] Delete Displayed Scenario',
  props<{ scenario: Scenario }>(),
);
export const CREATE_NEW_SCENARIO_LOCALLY = createAction('[🌱 Layout] Create new Scenario locally');
export const CREATE_NEW_SCENARIO_LOCALLY_SUCCESS = createAction(
  '[🤖 Layout Effect] Create new Scenario locally Success',
  props<{ scenario: Scenario }>(),
);
export const EDIT_SCENARIO = createAction(
  '[🌱 Layout] Edit Scenario',
  props<{ scenario: Scenario }>(),
);
export const EDIT_SCENARIO_SUCCESS = createAction(
  '[🌱 Layout] Edit Scenario Success',
  props<{ scenario: Scenario }>(),
);
export const SWITCH_SCENARIO_EDIT_MODE = createAction(
  '[🌱 Layout] Switch Scenario edit mode',
  props<{ editing: boolean }>(),
);
export const CANCEL_SCENARIO_EDITING = createAction('[🌱 Layout] Cancel editing Scenario');

// TODO: migrate logic of creating new plan to store
export const SELECT_PLAN = createAction('[🌱 Layout] Select Plan', props<{ plan: Plan }>());
export const BEGIN_CREATE_NEW_PLAN = createAction(
  '[🌱 Layout] Creating new Plan...',
  props<{ params: ICreatePlanImportParams }>(),
);
export const CREATE_NEW_PLAN_SUCCESS = createAction(
  '[⚡️ Layout] New Plan created',
  props<{ plan: Plan }>(),
);
export const CREATE_NEW_PLAN_FAILED = createAction(
  '[⚡️ Layout] Failed to create new Plan!',
  props<{ error: any }>(),
);
export const BEGIN_UPDATE_PLAN = createAction(
  '[🌱 Layout] Updating Plan...',
  props<{ params: IUpdatePlanImportParams }>(),
);
export const UPDATE_PLAN_SUCCESS = createAction(
  '[⚡️ Layout] Update Plan successfully',
  props<{ plan: Plan }>(),
);
export const UPDATE_PLAN_FAILED = createAction(
  '[⚡️ Layout] Update Plan failed',
  props<{ error: any }>(),
);
export const BEGIN_DELETE_PLAN = createAction(
  '[⚡ Plan API] Delete Plan',
  props<{ params: IDeletePlanParams }>(),
);
export const DELETE_PLAN_SUCCESS = createAction('[🤖 Plan API] Delete Plan success');
export const DELETE_PLAN_FAILED = createAction(
  '[🤖 Plan API] Delete Plan failed',
  props<{ error: any }>(),
);
export const SELECTED_DATE_RANGE_CHANGED = createAction(
  '[🌱 Layout] Select DateRange Changed',
  props<{ data: SimpleDateRange | undefined }>(),
);
export const SELECTED_DATE_RANGE_FORECAST_CHANGED = createAction(
  '[🌱 Layout] Select DateRange Forecast Changed',
  props<{ data: SimpleDateRange | undefined }>(),
);
export const SAVE_SEGMENT = createAction(
  '[🌱 Layout] Save Segment',
  props<{ segmentName: string }>(),
);
export const DELETE_SEGMENT = createAction(
  '[🌱 Layout] Delete Segment',
  props<{ segment: Segment }>(),
);
export const EDIT_SEGMENT = createAction(
  '[🌱 Layout] Edit Segment',
  props<{ segment: Segment }>(),
);
export const UPDATE_SELECTED_SEGMENT = createAction(
  '[🌱 Layout] Update selected Segment',
  props<{
    product?: SegmentFilter[];
    customer?: SegmentFilter[];
    distributor?: SegmentFilter[];
    location?: SegmentFilter[];
    dc?: SegmentFilter[];
  }>(),
);
export const SELECTED_SEGMENT_CHANGED = createAction(
  '[🌱 Layout] Selected Segment changed',
  props<{ segment?: Segment }>(),
);

export const DO_LOGOUT = createAction('[🌱 Layout] Logout');

export const CALCULATE_FORECAST_EXPLORER_METRICS = createAction(
  '[🌱 Layout] Calculating Forecast Explorer Metrics'
);
export const FETCH_PENDING_FORECAST_EXPLORER_TASKS = createAction(
  '[🌱 Layout] Fetch Pending Forecast Explorer Tasks'
);
export const FETCH_PENDING_FORECAST_EXPLORER_TASKS_SUCCESS = createAction(
  '[🤖 Layout] Fetch Pending Forecast Explorer Tasks Success',
  props<{ data: any }>(),
);
export const FETCH_PENDING_FORECAST_EXPLORER_TASKS_FAILED = createAction(
  '[🤖 Layout] Fetch Pending Forecast Explorer Tasks failed',
  props<{ error: any }>(),
);
export const CALCULATE_FORECAST_EXPLORER_METRICS_FAILED = createAction(
  '[🤖 Layout] Load Demand Chart data failed',
  props<{ error: any }>(),
);
export const RENDER_FORECAST_REPORT = createAction(
  '[🌱 Layout] Render Forecast Report',
  props<{
    params: IRenderForecastReportParams
  }>()
);
export const RENDER_FORECAST_REPORT_SUCCESS = createAction(
  '[🤖 Layout] Render Forecast Report success',
);
export const RENDER_FORECAST_REPORT_FAILED = createAction(
  '[🤖 Layout] Render Forecast Report failed',
  props<{ error: any }>(),
);
export const FETCH_PENDING_FORECAST_REPORT_TASKS = createAction(
  '[🌱 Layout] Fetch Pending Forecast Report Tasks'
);
export const FETCH_PENDING_FORECAST_REPORT_TASKS_SUCCESS = createAction(
  '[🤖 Layout] Fetch Pending Forecast Report Tasks Success',
  props<{ data: any }>(),
);
export const FETCH_PENDING_FORECAST_REPORT_TASKS_FAILED = createAction(
  '[🤖 Layout] Fetch Pending Forecast Report Tasks failed',
  props<{ error: any }>(),
);
export const FORECAST_REPORT_TASKS_RAN_SUCCESSFULLY = createAction(
  '[🤖 Layout] Forecast report task ran successfully'
);
export const START_CHECKING_FORECAST_REPORT_TASK = createAction(
  '[⚡ Layout] Checking forecast report task',
  props<{ taskId: string }>(),
);

export const STOP_CHECKING_FORECAST_REPORT_TASK = createAction(
  '[⚡ Layout] Stop checking forecast report task'
);

