import { select_selectedSegment } from '@/store/pages/layout/layout.baseSelectors';
import { select_segments } from '@/store/segment/segment.selectors';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** Shows up to specify name of a newly created saved segment. */
@Component({
  selector: 'cel-planning-segment-dialog',
  templateUrl: './planning-segment-dialog.component.html',
  styleUrls: ['./planning-segment-dialog.component.scss'],
})
export class PlanningSegmentDialogComponent implements OnInit, OnDestroy {
  segments = this.store.select(select_segments);
  selectedSegmentId?: string = '';
  choseSegment?: any;
  searchSegment = '';
  name = '';
  updateMode = false;
  destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly dialogRef: NbDialogRef<string>,
    private readonly store: Store) { }
  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.store.select(select_selectedSegment).pipe(
      takeUntil(this.destroy$)
    ).subscribe(result => {
      this.selectedSegmentId = result.id
    })
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save() {
    if (!this.updateMode) {
      this.dialogRef.close({
        name: this.name
      });
    } else {
      this.dialogRef.close({
        choseSegment: this.choseSegment
      });
    }
  }
  changeMode(checked: boolean) {
    this.updateMode = checked;
  }
  changeOption(value) {
    this.choseSegment = value;
  }
}
