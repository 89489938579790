import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DimensionGroup, DIMENSION_GROUPS, SANOFI_DIMENSION_GROUPS } from '@/app/@core/interfaces/business/breakdown';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { ECharts, EChartsOption } from 'echarts';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select_segmentCustomersOptions, select_segmentProductsOptions } from '@/store/segment/segment.selectors';
import { select_params_loadForecastSettingsChartData, select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { ForecastSettings, Workspace } from '@/app/@core/interfaces/common/workspace';
import { BEGIN_UPDATE_WORKSPACE_SETTINGS, BEGIN_UPDATE_WORKSPACE_SETTINGS_FOR_SANOFI } from '@/store/workspace/workspace.actions';
import { DEFAULT_ECHARTS_OPTIONS, DEFAULT_ECHARTS_SERIES_OPTIONS, formatByDateAggregationGen, takeRowDataById } from '@/store/pages/demand-planning/demand-planning.utils';
import { DemandService } from '@/app/@core/entity/demand.service';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import { DateAggregationOption } from '../timeseries/timeseries.constants';
import { sumIfHaveValue } from '@/utils/numbers';

@Component({
  selector: 'cel-forecast-settings',
  templateUrl: './forecast-settings.component.html',
  styleUrls: ['./forecast-settings.component.scss'],
})
export class ForecastSettingsComponent implements OnInit, OnDestroy {
  echartsInstance?: ECharts;
  chartOption: EChartsOption = {
    grid: {
      width: 295, height: 150, top: 10, left: 0
    },
    xAxis: {
      show: false,
      type: 'category',
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: false
      }
    },
  };
  DEFAULT_ECHARTS_OPTIONS = DEFAULT_ECHARTS_OPTIONS;
  productChartOptions: EChartsOption = {};
  customerChartOptions: EChartsOption = {};

  destroy$: Subject<void> = new Subject<void>();

  @Input() selectedHorizon: string = '6 Months';
  @Input() selectedtimeAggr: string = 'Month';

  constructor(
    private readonly dialogRef: NbDialogRef<string[]>,
    private readonly store: Store,
    private readonly demandService: DemandService,
  ) { }

  horizons: string[] = ['6 Months', '1 year', '18 Months', '2 years', '3 years', '5 years'];
  timeAggrs: string[] = ['Month', 'Week', 'Day'];
  productGroups: Array<DimensionGroup> = DIMENSION_GROUPS.filter(dim => dim.name === "Product");
  customerLocationGroups: Array<DimensionGroup> = DIMENSION_GROUPS.filter(dim => dim.name !== "Product");
  selectedProductAggr: string[] = ["ProductID"];
  selectedCustomerAggr: string[] = ["CustomerRef"];
  productsOptions: string[] = [];
  customersOptions: string[] = [];
  selectedProductsOption: string = "";
  selectedCustomersOption: string = "";
  delistedProductRef: string[] = [];
  userDefinedDelistedProductRef: string[] = [];
  delistedCustomerRef: string[] = [];
  forecastConfig: ForecastSettings | undefined = undefined;
  workspace: Workspace | undefined = undefined;
  params: any = undefined;
  productChartDataLoading = false;
  customerChartDataLoading = false;
  readonly SANOFI_PRODUCT_AGGR = ['ProductID'];
  readonly SANOFI_CUSTOMER_AGGR = ['Channel', 'Region'];

  ngOnInit(): void {
    combineLatest([
      this.store.select(select_segmentProductsOptions),
      this.store.select(select_segmentCustomersOptions),
      this.store.select(select_selectedWorkspace),
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([productsOptions, customersOptions, workspace]) => {
      this.productsOptions = productsOptions.map(option => option.productId as string);
      this.customersOptions = customersOptions.map(option => option.ref as string);

      this.delistedProductRef = productsOptions
        .filter(option => option.status === "Inactive")
        .map(option => option.productId) as string[];

      if (workspace != undefined && workspace.hasOwnProperty("settings")) {
        this.forecastConfig = workspace.settings?.forecastConfig;
      }

      if (this.forecastConfig != undefined) {
        this.selectedHorizon = this.forecastConfig.ForecastHorizon;
        this.selectedProductAggr = this.forecastConfig.ProductAggregation;
        this.selectedCustomerAggr = this.forecastConfig.CustomerAggregation;
        this.selectedtimeAggr = this.forecastConfig.TimeAggregation;
        this.userDefinedDelistedProductRef = this.forecastConfig.UserDefinedProductDelisting || [];
        this.userDefinedDelistedProductRef.forEach((product) => {
          if (!this.delistedProductRef.includes(product)) {
            this.delistedProductRef.push(product);
          }
        });
        this.delistedCustomerRef = this.forecastConfig.CustomerDelisting || [];
        this.selectedProductsOption = this.delistedProductRef[0];
        this.selectedCustomersOption = this.delistedCustomerRef[0];

        this.store.select(select_params_loadForecastSettingsChartData).pipe(
          takeUntil(this.destroy$)
        ).subscribe((params) => {
          this.params = params;
          this.updateCharts();
        });

        // Check condition to resetDefaultOptionsForSanofi
        this.checkSegmentGroups(workspace, this.selectedProductAggr, this.selectedCustomerAggr);
      }

      this.workspace = workspace;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  // Checks if there are elements in the sourceArray that are not present in the targetArray.
  hasElementsNotInArray(sourceArray, targetArray) {
    return sourceArray.some((element) => !targetArray.includes(element));
  }

  checkSegmentGroups(workspace: Workspace, selectedProductAggr: string[], selectedCustomerAggr: string[]) {
    const isSanofiVietnam = workspace?.name === "Sanofi Vietnam";

    this.productGroups = isSanofiVietnam ? SANOFI_DIMENSION_GROUPS.filter(dim => dim.name === "Product") : DIMENSION_GROUPS.filter(dim => dim.name === "Product");
    this.customerLocationGroups = isSanofiVietnam ? SANOFI_DIMENSION_GROUPS.filter(dim => dim.name !== "Product") : DIMENSION_GROUPS.filter(dim => dim.name !== "Product");

    if (isSanofiVietnam && (this.hasElementsNotInArray(selectedProductAggr, this.SANOFI_PRODUCT_AGGR) || this.hasElementsNotInArray(selectedCustomerAggr, this.SANOFI_CUSTOMER_AGGR))) {
      this.resetDefaultOptionsForSanofi();
    }
  }

  resetDefaultOptionsForSanofi() {
    const forecastConfig = {
      ForecastHorizon: this.selectedHorizon,
      ProductAggregation: this.SANOFI_PRODUCT_AGGR,
      CustomerAggregation: this.SANOFI_CUSTOMER_AGGR,
      TimeAggregation: this.selectedtimeAggr,
      ProductDelisting: this.delistedProductRef,
      UserDefinedProductDelisting: this.userDefinedDelistedProductRef,
      CustomerDelisting: this.delistedCustomerRef,
    }

    this.store.dispatch(
      BEGIN_UPDATE_WORKSPACE_SETTINGS_FOR_SANOFI({
        params: {
          id: this.workspace?.id as string,
          settings: JSON.stringify(
            {
              ...this.workspace?.settings,
              forecastConfig
            }
          )
        }
      })
    );
  }

  save() {
    const forecastConfig = {
      ForecastHorizon: this.selectedHorizon,
      ProductAggregation: this.selectedProductAggr,
      CustomerAggregation: this.selectedCustomerAggr,
      TimeAggregation: this.selectedtimeAggr,
      ProductDelisting: this.delistedProductRef,
      UserDefinedProductDelisting: this.userDefinedDelistedProductRef,
      CustomerDelisting: this.delistedCustomerRef,
    }
    this.store.dispatch(
      BEGIN_UPDATE_WORKSPACE_SETTINGS({
        params: {
          id: this.workspace?.id as string,
          settings: JSON.stringify(
            {
              ...this.workspace?.settings,
              forecastConfig
            }
          )
        }
      })
    );
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  changeHorizon(horizon: string): void {
    this.selectedHorizon = horizon;
  }

  updateCharts() {
    this.getEchartsOption(this.selectedProductsOption, "product");
    this.getEchartsOption(this.selectedCustomersOption, "customer");
  }

  changeTimeAggr(timeAggr: string): void {
    this.selectedtimeAggr = timeAggr;
  }

  onRadioClick(horizon: string): void {
    this.selectedHorizon = horizon;
  }

  changeSelectedProductAggr(value: string[]): void {
    this.selectedProductAggr = value;
  }

  changeSelectedCustomerAggr(value: string[]): void {
    this.selectedCustomerAggr = value;
  }

  getEchartsOption(value: string, type: string) {
    if (type === "product") {
      this.productChartDataLoading = true;
    } else if (type === "customer") {
      this.customerChartDataLoading = true;
    }
    const newParams = {
      ...this.params,
      interval: 'M', // this.selectedtimeAggr.split("")[0],
      segment: {
        customer: type === "customer" ? [
          {
            shipTo: [value],
          }
        ] : [],
        dc: [],
        formatVersion: '1',
        location: [],
        name: "",
        product: type === "product" ? [
          {
            productId: [value],
          }
        ] : [],
        type: "filter",
      },
      groupingColumns: this.selectedCustomerAggr.map(customer => `Customer.${customer}`).concat(this.selectedProductAggr.map(product => `Product.${product}`))
    }

    this.demandService.getDemands(newParams).pipe(
      takeUntil(this.destroy$)
    ).subscribe((data => {
      const scenarios = [{
        id: "actual",
        name: "Actual",
        color: "#979797"
      }];
      const rows: any = scenarios.map((s) => {
        const demands =
          takeRowDataById(s.id, data.rows) ||
          takeRowDataById('forecast', data.rows) ||
          [];
        return [s.id, demands.map((val, i) => sumIfHaveValue(
          val ? val : 0,
          0))];
      });

      const combinedChartData = {
        columns: data.columns,
        rows
      }

      const echartsOption = <EChartsOption>{
        // tooltip: {
        //   trigger: 'axis'
        // },
        xAxis: {
          data: combinedChartData.columns,
          axisLabel: { formatter: formatByDateAggregationGen(DateAggregationOption.MONTH, this.workspace?.settings?.fiscalYearStartMonth) },
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => {
              return transformInputToKpiFormat(value, '-', this.workspace ? this.workspace.settings?.acceptedScale : [])
            }
          },
          splitLine: { show: true },
        },
        color: scenarios.map((s) => s.color || '#0C80EB'),
        series: scenarios.map(({ id, name }) => ({
          id,
          name,
          emphasis: { disabled: true },
          data: takeRowDataById(id, combinedChartData.rows),
          ...DEFAULT_ECHARTS_SERIES_OPTIONS,
          lineStyle: { width: 1 },
          showSymbol: combinedChartData.columns.length > 1 ? false : true,
          type: 'line'
        })),
      };

      if (type === "product") {
        this.productChartOptions = echartsOption;
        this.productChartDataLoading = false;
      } else if (type === "customer") {
        this.customerChartOptions = echartsOption;
        this.customerChartDataLoading = false;
      }
    }));
  }

  changeProductsOption(value: string): void {
    this.selectedProductsOption = value;
    this.getEchartsOption(value, "product");
  }

  changeCustomersOption(value: string): void {
    this.selectedCustomersOption = value;
    this.getEchartsOption(value, "customer");
  }

  addDelistedProductRef(): void {
    if (this.selectedProductsOption !== "" && !this.delistedProductRef.includes(this.selectedProductsOption)) {
      this.delistedProductRef = [...this.delistedProductRef, this.selectedProductsOption];
      this.userDefinedDelistedProductRef = [...this.userDefinedDelistedProductRef, this.selectedProductsOption];
    }
  }

  addDelistedCustomerRef(): void {
    if (this.selectedCustomersOption !== "" && !this.delistedCustomerRef.includes(this.selectedCustomersOption)) {
      this.delistedCustomerRef = [...this.delistedCustomerRef, this.selectedCustomersOption];
    }
  }
}
