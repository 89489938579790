import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { Adjustment, AdjustmentType, EventType } from '../../../../../@core/interfaces/business/event';
import { PartialSharedAdjustmentType, SharedAdjustmentType } from './segment-impact.component';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { select_presettedDateRanges } from '@/store/pages/layout/layout.selectors';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { WorkspaceService } from '@/app/@core/utils';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { select_disabledDateRangeFn } from '@/store/pages/event-management/event-management.selectors';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { endOfMonth, format } from 'date-fns';
import { countDecimalSeparators, formatNumberInput, getNumericSeparators, removeThousandSeparators, sanitizeForNumberInput } from '@/app/@components/business/planning-segment/planning-segment.utils';

/** Indicator whether to have an increase or decrease adjustment. */
export enum AdjustmentSign {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  ABSOLUTE = 'absolute',
}

/** Internal representation of a sign in this component. */
interface SignedAdjustment extends Adjustment {
  sign: AdjustmentSign;
}

/** Returns the value of a signed adjustment, with its sign applied. */
function signedValueOf(signedAdjustment: SignedAdjustment) {
  const multiplier = signedAdjustment.sign === AdjustmentSign.DECREASE ? -1 : 1;
  return signedAdjustment.value * multiplier;
}

/** Loads an Adjustment into a SignedAdjustment. Allows non-toggling of sign if same value. */
function loadAdjustmentToSigned(base?: SignedAdjustment, apply?: Adjustment, eventType?: EventType): SignedAdjustment {
  // Magic happens here. This allows users to increase / decrease value without toggling the
  // displayed adjustment sign.
  if (base && signedValueOf(base) === apply?.value) {
    return { ...base, type: apply.type };
  }
  // Naive clause if base is falsy.
  const isInventoryParametersEvent = eventType === EventType.INVENTORY_PARAMETERS;
  return {
    sign: isInventoryParametersEvent ? AdjustmentSign.ABSOLUTE : (apply?.value || 0) >= 0 ? AdjustmentSign.INCREASE : AdjustmentSign.DECREASE,
    value: Math.abs(apply?.value || 0) || 0,
    type: isInventoryParametersEvent ? AdjustmentType.DAYS : apply?.type || AdjustmentType.ABSOLUTE,
  };
}

@Component({
  selector: 'cel-segment-adjustment',
  templateUrl: './segment-adjustment.component.html',
  styleUrls: ['./segment-adjustment.component.scss'],
})
export class SegmentAdjustmentComponent implements OnChanges, OnInit, OnDestroy {
  /** Label to use in the adjustment. */
  @Input() name = '';
  /** The adjustment being edited. */
  @Input() adjustment?: Adjustment;
  @Input() startDate?: string;
  @Input() endDate?: string;
  @Input() eventDetail?: any;
  @Input() eventType?: EventType;
  @Input() parentData?: SharedAdjustmentType;
  @Output() dataChanged = new EventEmitter<PartialSharedAdjustmentType>();
  checked: boolean = false;
  disabled: boolean = false;
  private firstRangeRun: boolean = true;
  private firstRun: boolean = true;

  /** Emits when the adjustment has been edited. */
  @Output() adjustmentChange = new EventEmitter<Adjustment>();
  @Output() dateRangeChanged = new EventEmitter<SimpleDateRange>();

  /** The adjustment rendered in the UI. Parsed from input adjustment. */
  signedAdjustment?: SignedAdjustment;

  /** Expose enum to template. */
  readonly AdjustmentType = AdjustmentType;
  readonly AdjustmentSign = AdjustmentSign;
  readonly EventType = EventType;

  @Input() dateRange!: SimpleDateRange;
  dateRangeSelectionText = "Date";
  displayMonthYearOnly: boolean = false;
  selectableDateRangeFn = this.store.select(select_disabledDateRangeFn);
  presettedDateRanges = this.store.select(select_presettedDateRanges);
  workspace$ = this.store.select(select_selectedWorkspace);
  noDateAllowed = (_: Date) => false;
  @Output() deleteAdjustment = new EventEmitter<void>();
  formControl_selectedRange = new FormControl([]);
  selectedDateRange: (Date | undefined)[] = [];
  formatNumberInput = formatNumberInput;

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    this.workspace$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((workspace: Workspace) => {
      this.displayMonthYearOnly = this.workspaceService.checkIsDisplayMonthYearOnly(workspace);

      if (this.displayMonthYearOnly) {
        this.dateRangeSelectionText = "Month";
      } else {
        this.dateRangeSelectionText = "Date";
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('dateRange' in changes && this.dateRange && this.dateRange.start && this.dateRange.end) {
      const startDate = new Date(this.dateRange.start);
      const endDate = new Date(this.dateRange.end);
      if (this.firstRangeRun) {
        this.selectedDateRange = [startDate, endDate];
        this.emitDateRange();
        this.firstRangeRun = false;
      }
    }

    if ('adjustment' in changes) {
      this.signedAdjustment = loadAdjustmentToSigned(this.signedAdjustment, this.adjustment, this.eventType);
      if (this.firstRun) {
        this.dataChanged.emit({
          sign: this.signedAdjustment.sign,
          value: this.signedAdjustment.value,
          type: this.signedAdjustment.type
        });
        this.firstRun = false;
      }
    }

    if ('startDate' in changes || 'endDate' in changes) {
      if (this.startDate && this.endDate) {
        this.selectedDateRange = [
          new Date(this.startDate),
          new Date(this.endDate),
        ];
        this.emitDateRange();
      }
    }

    if (this.shouldDisplayPED() && changes.parentData) {
      if (!this.signedAdjustment || !this.parentData) return;

      if (this.parentData.checked) {
        if (this.parentData.sign === AdjustmentSign.INCREASE) {
          this.signedAdjustment.sign = AdjustmentSign.DECREASE;
        } else if (this.parentData.sign === AdjustmentSign.DECREASE) {
          this.signedAdjustment.sign = AdjustmentSign.INCREASE;
        } else {
          this.signedAdjustment.sign = AdjustmentSign.ABSOLUTE;
        }
  
        if (this.name === "Demand") {
          this.signedAdjustment.value = parseFloat((this.parentData.value * 1.67).toFixed(2));
        } else if (this.name === "Product Price") {
          this.signedAdjustment.value = parseFloat((this.parentData.value / 1.67).toFixed(2));
        }
        
        this.signedAdjustment.type = this.parentData.type;

        this.emitAdjustment({
          sign: this.signedAdjustment.sign,
          value: this.signedAdjustment.value,
          type: this.signedAdjustment.type
        });
  
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    }
  }

  updateSign(sign: AdjustmentSign) {
    if (!this.signedAdjustment) return;
    this.signedAdjustment.sign = sign;
    this.emitAdjustment({ sign });
  }

  updateValue(value: string) {
    if (!this.signedAdjustment) return;
    const removedCommaValue = removeThousandSeparators(value);
    const sanitizedValue = sanitizeForNumberInput(removedCommaValue);
    this.signedAdjustment.value = Number(sanitizedValue) || 0;
    this.emitAdjustment({ value: this.signedAdjustment.value });
  }

  onInputKeyUp(event: KeyboardEvent): void {
    if (event.target instanceof HTMLInputElement) {
      const inputValue = event.target.value;
      
      const [, decimalSeparator] = getNumericSeparators();
      const hasOnlyOneDot = countDecimalSeparators(inputValue) === 1;
      const endsWithDecimalOrZero = inputValue.endsWith(decimalSeparator) || inputValue.endsWith('0');
      if (!(hasOnlyOneDot && endsWithDecimalOrZero)) {
        const removedCommaValue = removeThousandSeparators(inputValue);
        const sanitizedValue = sanitizeForNumberInput(removedCommaValue);
        event.target.value = formatNumberInput(Number(sanitizedValue) || 0);
      }
    }
  }

  updateType(type: AdjustmentType) {
    if (!this.signedAdjustment) return;
    this.signedAdjustment.type = type;
    this.emitAdjustment({ type });
  }

  checkedChange(checked: boolean) {
    if (!this.signedAdjustment) return;
    this.emitAdjustment({ checked });
  }

  private emitAdjustment(data: PartialSharedAdjustmentType) {
    if (!this.signedAdjustment) return;
    this.adjustmentChange.emit({
      value: signedValueOf(this.signedAdjustment),
      type: this.signedAdjustment.type,
    });
    this.dataChanged.emit(data);
  }

  private emitDateRange() {
    if (this.selectedDateRange.length === 2) {
      const startDate = this.selectedDateRange[0];
      const endDate = this.selectedDateRange[1];
  
      if (startDate != null && endDate != null) {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  
        this.dateRangeChanged.emit({ start: formattedStartDate, end: formattedEndDate });
      }
    }
  }

  shouldDisplayPED(): boolean {
    return this.eventType ? [EventType.GENERAL_PRICING, EventType.PROMOTION_CAMPAIGN].includes(this.eventType) : false;
  }

  onDateChange(result: (Date | null)[]): void {
    let startDate: Date | undefined = this.selectedDateRange[0];
    let endDate: Date | undefined = this.selectedDateRange[1];
  
    if (result.length >= 1 && result[0] != null) {
      startDate = new Date(result[0]);
  
      if (result.length === 2 && result[1] != null) {
        endDate = new Date(result[1]);
        if (this.displayMonthYearOnly) {
          // end of month
          endDate = endOfMonth(endDate);
        }
      }
    }
  
    this.selectedDateRange = [startDate, endDate];
    this.emitDateRange();
  }

  deleteSegmentAdjustment() {
    this.deleteAdjustment.emit();
  }

  preventScroll(event: WheelEvent) {
    event.preventDefault();
  }
}
